@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Button {
  @include font(bold);

  --main: #{color(white)};
  --accent: #{color(secondary)};

  display: inline-flex;
  align-items: center;
  padding: px(1.5) px(2);
  border-radius: v(radius-button);
  box-shadow: borderinset(v(accent));
  outline-color: v(accent);
  transition: color v(timings-xs), background-color v(timings-xs);

  &.isLoading,
  &.isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &,
  &.isOutline:hover {
    background-color: v(accent);
    color: v(main);
  }

  &:hover,
  &.isOutline {
    background-color: v(main);
    color: v(accent);
  }

  &.isPrimary {
    --main: #{color(white)};
    --accent: #{color(primary)};
  }

  &.isSecondary {
    --main: #{color(white)};
    --accent: #{color(secondary)};
  }

  &.isRed {
    --main: #{color(white)};
    --accent: #{color(red)};
  }

  .IconLeft {
    display: flex;
    width: px(4);
    height: px(4);
    align-items: center;
    justify-content: center;
    margin-right: px(2);
    background-color: color(white);
    border-radius: 50%;
  }

  .IconRight {
    margin-left: px(2);
  }

  .Label {
    flex: 1;
  }
}
