@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Progress {
  --trackColor: #{color(gray)};
  --trackHeight: 2px;
  --indicatorColor: #{color(progress)};
  --indicatorHeight: 4px;

  display: flex;
  overflow: visible;
  height: v(trackHeight);
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  background-color: v(trackColor);
  border-radius: v(radius-round);

  .Indicator {
    width: v(progress, 0);
    height: v(indicatorHeight);
    padding: calc(v(indicatorHeight) / 2);
    animation: progress-indicator v(timings-xl) v(easing-out) none;
    background-color: v(indicatorColor);
    border-radius: v(radius-round);
    transition: width v(timings-xl) v(easing-out), background-color v(timings-md) v(easing-inOut);

    @keyframes progress-indicator {
      from {
        width: 0;
      }
    }
  }

  &.isLarge {
    --indicatorHeight: #{px(1.5)};
    --trackColor: #{color(white, shadow)};
  }
}
