@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.UpdateRoomForm {
  .FormFieldsWrapper {
    @include media-breakpoint-down(sm) {
      grid-auto-flow: row;
      grid-template-columns: unset;
      grid-template-rows: repeat(auto-fit, minmax(0, max-content));
    }
  }

  .FormCheckboxWrapper {
    margin-top: 1rem;

    @include media-breakpoint-down(sm) {
      grid-auto-flow: row;
      grid-template-columns: unset;
      grid-template-rows: repeat(auto-fit, minmax(0, max-content));
    }
  }

  .StatisticWrapper {
    & div {
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }

  .Input {
    margin-block-end: 0;
  }

  .SubmitWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: px(2);
  }
}
