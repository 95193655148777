@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Tabs {
  .List {
    display: grid;
    justify-content: start;
    border-block-end: 2px solid color(gray, light);
    gap: px(4);
    grid-auto-flow: column;
    margin-block-end: px(1.5);
  }
}
