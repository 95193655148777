@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.RoomSkeleton {
  display: flex;
  background-color: color(white);

  .Image {
    position: relative;
    width: 160px;
    background-color: #dddbdd;

    &::after {
      position: absolute;
      animation: shimmer 2s infinite;
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      content: '';
      inset: 0;
      transform: translateX(-100%);
    }
  }

  .Layout {
    position: relative;
    flex: 1;
    padding: px(3);
    background-color: color(white);

    .Title {
      margin-block-end: px(1);
    }
  }

  .Buttons {
    display: grid;
    padding: px(3);
    gap: px(2);
    grid-auto-rows: max-content;
    padding-inline-start: 0;
  }

  .Loader {
    position: relative;
    display: inline-block;
    overflow: hidden;
    height: 1em;
    background-color: #dddbdd;

    &::after {
      position: absolute;
      animation: shimmer 2s infinite;
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      content: '';
      inset: 0;
      transform: translateX(-100%);
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
