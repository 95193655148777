@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.SolutionCardRow {
  .TitleActions {
    display: flex;
    justify-content: space-between;
    gap: px(2);
  }

  .Title {
    margin-bottom: px(1);
  }

  .Content {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: px(2) px(2) px(2) px(4);
  }

  .ButtonsWrapper {
    display: flex;
    align-items: flex-start;
    gap: px(2);
  }
}

.Modal {
  .Footer {
    display: flex;
    margin-top: px(2);
  }

  .Confirm {
    margin-right: px(2);
  }
}
