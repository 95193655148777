@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.ProjectCard {
  display: flex;

  .Main {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;

    .Title {
      @include font(h3);
    }

    .Date {
      display: inline-flex;
      align-items: baseline;
      margin-top: px(0.5);

      .Icon {
        margin-right: px(1.5);
        line-height: 1;
      }
    }

    .Footer {
      margin-top: auto;

      .Button {
        display: inline-flex;
        margin-top: px(3);
      }
    }
  }
}
