@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.AdvisorAccountScreen {
  margin-block-end: px(4);
  margin-block-start: px(8);

  .Header {
    display: grid;
    grid-template: 'title button' / 1fr auto;
    margin-block-end: px(10);

    .Title {
      grid-area: title;
    }

    .Button {
      grid-area: button;
    }
  }

  .FontWeightNormal {
    font-weight: 400;
  }

  .Image {
    display: block;
    max-width: 300px;
    max-height: 300px;
    border-radius: v(radius-md);
    box-shadow: v(shadow-large);
    object-fit: contain;
  }
}
