@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.CardRow {
  display: flex;
  min-height: 125px;
  background-color: color(white);

  &.isblue {
    background-color: color(primary, background);
  }

  &.isLarge {
    min-height: 160px;

    .Image {
      width: 160px;
    }
  }

  .Image {
    width: 125px;
    object-fit: cover;
  }

  @include media-breakpoint-down(sm) {
    min-height: 80px;

    .Image {
      width: 80px;
    }

    &.isLarge {
      min-height: 100px;

      .Image {
        width: 100px;
      }
    }
  }
}
