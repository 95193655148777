@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.InputRadio {
  width: 100%;
  margin: 0 px(-0.5);

  .Option {
    margin: 0 px(0.5) px(1) px(0.5);
  }

  &.isLoading {
    opacity: 0.5;
  }
}
