@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Anchor {
  display: inline-flex;
  color: v(color);
  font-weight: bold;
  transition: text-decoration-color v(timings-xs);

  .Icon {
    margin-left: px(1);
  }

  &:hover {
    text-decoration-color: color(primary);
  }

  &.hasUnderline {
    text-decoration: underline;
  }

  &.isPrimary {
    color: color(primary);
  }

  &.isSecondary {
    color: color(secondary);
  }

  &.isRed {
    color: color(red);
  }

  &.isReverse {
    flex-direction: row-reverse;

    .Icon {
      margin-right: px(1);
      margin-left: 0;
    }
  }
}
