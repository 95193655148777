@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Chart {
  display: grid;
  padding: px(1.5);
  background-color: #f3f9fc;
  border-radius: v(radius-xs);
  gap: px(1.5);

  .Icon {
    margin-left: auto;
    cursor: pointer;
  }

  .Wrapper {
    display: flex;
  }
}

.Modal {
  min-width: 90vw;
}
