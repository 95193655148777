@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.AdvisorHomeScreen {
  margin: px(4) 0;

  .Header {
    display: grid;
    grid-template: 'title button' / 1fr auto;

    .Title {
      grid-area: title;
    }

    .Button {
      grid-area: button;
    }
  }
}
