@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Cta {
  display: inline-flex;
  align-items: center;
  padding: px(2);

  .IconLeft {
    margin-right: px(2);
  }

  .Text {
    margin-right: auto;
  }

  .IconRight {
    margin-left: px(2);
  }
}
