@import '../../../../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../../../../assets/styles/abstracts/functions';
@import '../../../../../../assets/styles/abstracts/mixins';

.Button {
  position: relative;
  padding: px(1.5) px(1);

  @include font(table);

  &[aria-selected='true'] {
    color: color(primary);

    &::after {
      position: absolute;
      right: 0;
      bottom: -3px;
      left: 0;
      display: block;
      height: px(0.5);
      background-color: color(primary);
      border-radius: 999px;
      content: '';
    }
  }
}
