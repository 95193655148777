@import '../../../../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../../../../assets/styles/abstracts/functions';
@import '../../../../../../assets/styles/abstracts/mixins';

.AddProductOverview {
  display: flex;
  flex-direction: column;

  .ModalContent {
    display: flex;
    overflow: auto;
    flex: 1;
    flex-direction: column;
  }

  .UpperWrapper {
    position: relative;
    z-index: 1;
    height: px(7.5);

    @include media-breakpoint-down(sm) {
      height: 230px;
    }
  }

  .Filters {
    position: fixed;
    display: flex;
    width: calc(100% - 2 * px(10));
    flex-direction: row;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
      width: calc(100% - 2 * px(4));
      flex-direction: column;

      .FilterGroup {
        margin: 0 !important;
      }
    }

    .FilterWrapper {
      position: absolute;
      display: flex;
      gap: px(2);
      width: 100%;
    }

    .FiltersSelect {
      display: flex;

      flex-grow: 1;
    }

    .FiltersSearch {
      flex-basis: 50%;

      input {
        padding: px(0.5) px(1);
        min-height: 38px;
      }
    }

    .FilterGroup {
      flex: 1;
      margin: 0 px(1);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .Label {
        margin-bottom: px(1);
        font-weight: bold;
      }
    }
  }

  .Products {
    position: relative;
    overflow: auto;
    flex: 1;
    margin-top: px(4);
    min-height: px(40);

    &.isLoading {
      opacity: 0.5;
    }

    .Product {
      margin-bottom: px(2);
    }
  }
}
