@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.RoomAdviceDetails {
  display: grid;
  gap: px(1.5);
  grid-template-areas: 'text accordion';
  grid-template-columns: max-content 1fr;

  .Line {
    height: 1px;
    background-color: color(gray, light);
    grid-column: 1 / -1;
  }

  .Title {
    font-weight: bold;
    line-height: 21px;
  }

  .Item {
    position: relative;
    display: grid;
    align-items: center;
    gap: px(0.5) px(1);
    grid-template-columns: max-content 1fr auto;

    .Content {
      grid-column: 2 / 3;
    }

    .Button {
      margin-left: px(2);

      &::after {
        position: absolute;
        content: '';
        inset: 0;
      }
    }
  }
}
