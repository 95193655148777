@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.GridList {
  --cols: #{v(columnsMobile, 1)};

  @include md(md) {
    --cols: #{v(columnsTablet, 2)};
  }

  @include md(xl) {
    --cols: #{v(columnsDesktop, 3)};
  }

  display: grid;
  gap: px(5) px(4);
  grid-template-columns: repeat(#{v(cols)}, 1fr);
}
