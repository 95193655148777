@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Modal {
  position: fixed;
  z-index: 1;
  top: 50vh;
  left: 50vw;
  width: 100%;
  max-width: 542px;
  max-height: 95vh;
  padding: px(4) px(10);
  animation: modal-fade-in normal v(timings-sm) ease-out both;
  background-color: color(white);
  border-radius: v(radius-md);
  box-shadow: v(shadow-large);
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    padding: px(4);
  }

  &.isLarge {
    max-width: 900px;
  }

  .Title {
    margin-bottom: px(3);
    color: color(primary);

    @include font(h2);
  }

  .Description {
    @include font(default);
  }

  .Close {
    position: absolute;
    top: px(3);
    right: px(3);
  }

  @keyframes modal-fade-in {
    0% {
      opacity: 0;
      transform: translate(-50%, calc(-50% - 25px)) scaleX(0.9);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scaleX(1);
    }
  }

  @include media-breakpoint-down(md) {
    max-width: 90%;
  }
}

.Overlay {
  position: absolute;
  z-index: 1;
  background-color: color(black-overlay);
  inset: 0;
}
