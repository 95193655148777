@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Video {
  width: 100%;

  .Overlay {
    position: absolute;
    display: grid;
    background-color: color(black, overlay);
    border-radius: v(radius-md);
    inset: 0;
    place-items: center;
    transition: background-color v(timings-xs);

    &:hover {
      background-color: color(black, overlayHover);
    }
  }

  .Wrapper {
    height: 100%;

    .Thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
