@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Icon {
  --iconColor: inherit;
  --iconBackgroundColor: inherit;
  --iconSize: 1em;

  display: flex;
  align-items: center;
  justify-content: center;
  color: v(iconColor);
  font-size: v(iconSize);

  &.isCentered {
    place-self: center;
  }

  &.hasBackground {
    display: grid;
    width: 2em;
    height: 2em;
    background-color: v(iconBackgroundColor);
    border-radius: v(radius-round);
    place-items: center;
  }

  &.hasCircle {
    border-radius: v(radius-round);
  }

  &.hasSquare {
    border-radius: v(radius-button);
  }
}
