@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.EditableTitle {
  display: inline-flex;
  align-items: flex-start;
  color: color(white);

  .Title,
  .Input,
  .InputSpacer {
    @include font(h1);
  }

  .InputWrapper {
    position: relative;
    width: min-content;
    min-width: 1em;
    padding: px(0.5) 0;
  }

  .Input {
    position: absolute;
    left: 0;
    width: 100%;
    background: none;
  }

  .InputSpacer {
    position: relative;
    visibility: hidden;
    white-space: pre;
  }

  .ButtonGroup {
    display: flex;
  }

  .Button {
    margin-left: px(1.5);
  }

  .ShareButton {
    margin-left: px(0.5);
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .ButtonGroup {
      margin-bottom: px(0.5);
    }

    .Button {
      margin-left: 0;
    }
  }
}
