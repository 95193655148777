@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.ProductCardRow {
  &.isClickable {
    cursor: pointer;
  }

  &.isLoading {
    opacity: 0.5;
  }

  .Content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: px(4);
  }
}
