@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.LazyList {
  .Notification {
    display: flex;
    min-height: 60px;
    align-items: center;
    justify-content: center;
    margin-top: px(4);

    .Icon {
      margin-right: px(1);
      color: color(green);
    }
  }
}
