@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Spinner {
  .Loader {
    position: relative;
    border: 1.1em solid;
    border-width: v(borderWidth);
    border-color: v(colorLight);
    border-left-color: v(color);
    margin: 0 auto;
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    text-indent: -9999em;
    transform: translateZ(0);

    &,
    &::after {
      width: v(size);
      height: v(size);
    }
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
