@import '../../../../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../../../../assets/styles/abstracts/functions';
@import '../../../../../../assets/styles/abstracts/mixins';

.CardImage {
  position: relative;
  display: inline-grid;
  width: 100%;
  height: 100%;
  background-image: v(gradient-placeholder);
  grid-template: 'row column' / 1fr 0;

  .Image,
  .Ratio,
  .ImageContents {
    width: 100%;
    height: 100%;
  }

  .Ratio {
    grid-area: v(layout);
  }

  .Spacing {
    width: px(15);
    grid-area: row;
  }

  .Image {
    position: absolute;
    grid-area: row;
    object-fit: cover;
  }

  .ImageContents {
    position: absolute;
    display: grid;
    grid-area: row;
    place-items: center;
  }
}
