@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Footer {
  padding: px(2) 0;
  background-color: color(primary);
  color: color(white);

  .Container {
    display: flex;
    align-items: flex-end;

    .Title {
      margin-right: auto;
      font-size: 12px;
    }
  }
}
