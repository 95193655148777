@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.FrequencyChart {
  display: grid;
  gap: px(4);
  grid-auto-flow: column;
  grid-template-columns: 125px 1fr;

  @include media-breakpoint-down(md) {
    gap: px(2);
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
}
