@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.InputFile {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: px(6) px(3);
  border-width: 2px;
  border-style: dashed;
  border-color: color(gray);
  border-radius: v(radius-md);
  cursor: pointer;

  .Icon {
    margin-right: px(2);
  }
}
