@import '../../../../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../../../../assets/styles/abstracts/functions';
@import '../../../../../../assets/styles/abstracts/mixins';

.Filter {
  padding: px(4);
  background-color: color(primary, background);
  border-radius: 11px;

  .Label {
    display: block;
    margin-bottom: px(1);
    font-weight: bold;
  }

  .MobileHeader {
    display: none;
    align-items: center;
    justify-content: space-between;
  }

  .Submit {
    display: none;
    width: 100%;
    margin-top: px(4);
  }

  @include media-breakpoint-down(lg) {
    position: fixed;
    z-index: 1;
    overflow: auto;
    background: color(white);
    inset: 0;
    opacity: 0;
    transform: translate3d(50px, 0, 0);
    transition: all 0.2s ease-in-out;
    visibility: hidden;

    .MobileHeader {
      display: flex;
    }

    .Submit {
      display: block;
    }

    &.isOpen {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  }
}
