@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.AdvisorAddEditSolution {
  display: flex;
  flex-direction: column;

  .Actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: px(3);
    border-top: 2px solid color(gray, light);
    margin: px(1) px(-0.5) 0 px(-0.5);

    > * {
      margin: 0 px(0.5);
    }
  }

  .ModalContent {
    display: flex;
    flex: 1;
    flex-direction: column;

    &.isLoading {
      opacity: 0.5;
    }

    .Introduction {
      margin-bottom: px(3);
    }
  }

  .Configurator {
    overflow: auto;
  }

  .Image {
    width: 160px;
    height: 160px;
    object-fit: cover;
  }
}
