@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Navbar {
  background-color: color(white-darker);

  .Container {
    display: flex;
    padding: px(3) 0;

    .Logo {
      width: 143px;
      height: 51px;
      margin-right: px(6);
    }

    .IconContainer {
      display: flex;
      align-items: center;
      margin-right: 0;
      margin-left: auto;

      .Icon {
        margin-left: px(4);

        @include media-breakpoint-down(md) {
          margin-left: px(3);
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding: px(1) px(2);
    }
  }
}

.MobileDropdown {
  padding: px(2) px(2);
  border-width: 1px;
  border-style: solid;
  border-color: color(gray);
  background-color: color(white-darker);
}

.isMobile {
  @include media-breakpoint-up(md) {
    display: none;
  }

  @include media-breakpoint-down(md) {
    margin-top: px(0.75);
  }
}

.isDesktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
