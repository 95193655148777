@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.SpacingGroup {
  display: grid;
  gap: px(v(gap));
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));

  &.isVertical {
    grid-auto-flow: row;
    grid-template-columns: unset;
    grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  }
}
