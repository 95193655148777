@import '../../../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../../../assets/styles/abstracts/functions';
@import '../../../../../assets/styles/abstracts/mixins';

.Options {
  margin: 0 px(-1.5);

  .Option {
    display: flex;
    align-items: center;
    gap: px(1);
  }

  .OptionLabel {
    width: px(16);
    font-weight: 700;
  }

  > * {
    margin: 0 px(1.5);
    margin-bottom: px(1);
  }

  .HexTag,
  .Image {
    width: px(5);
    height: px(2);
    border-radius: px(0.5);
    object-fit: cover;
  }
}
