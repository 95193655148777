@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Products {
  margin: px(4) 0;

  .Title {
    margin-bottom: px(2);
  }

  .OpenFiltersButton {
    display: none !important;
    display: block;
    margin-bottom: px(2);
    margin-left: auto;

    @include media-breakpoint-down(lg) {
      display: block !important;
    }
  }
}
