@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.ProjectRooms {
  display: grid;
  gap: px(2);
  grid-auto-flow: row;

  .Header {
    display: grid;
    grid-template: 'title button' / 1fr auto;

    .Title {
      grid-area: title;
    }

    .Button {
      grid-area: button;
    }
  }
}
