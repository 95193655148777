@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.QuoteCard {
  position: relative;
  padding: px(8) px(3);
  background-color: color(primary);
  background-image: v(gradient-fade);
  color: color(white);

  .Quotes {
    position: absolute;
    top: px(1);
    left: 0;
    opacity: 0.2;
  }

  .Quote {
    @include font(quote);
  }

  .Cite {
    @include font(sm);

    margin-top: px(2);
  }
}
