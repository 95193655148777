@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Statistic {
  display: flex;
  min-width: 125px;
  flex-direction: column;
  padding: px(2);
  background-color: color(primary-lighter);
  border-radius: v(radius-xs);

  .TooltipContainer {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    margin-top: px(0.5);
  }
}
