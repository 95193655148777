@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.ContactCardRow {
  position: relative;
  display: flex;

  .Image {
    width: 118px;
    object-fit: cover;
  }

  .Data {
    padding: px(5) px(2) px(2);

    .Title {
      margin-bottom: px(1);
      font-size: v(font-sm-fontsize);
    }

    .Email {
      display: block;
      margin-bottom: px(1);
      font-size: v(font-sm-fontsize);
      font-weight: normal;
    }

    .PhoneNumber {
      font-size: v(font-sm-fontsize);
      font-weight: normal;
      text-decoration: none;
    }

    .Icons {
      margin-top: px(2);
    }
  }

  .DataNoImage {
    padding: px(3) px(4);
  }
}
