@import '../../../../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../../../../assets/styles/abstracts/functions';
@import '../../../../../../assets/styles/abstracts/mixins';

.Column {
  --iconColor: inherit;

  &.isActive {
    --iconColor: #{color(primary)};
  }

  .Button {
    display: flex;
    width: 100%;
    height: 100%;
    padding: px(3);

    @include font(table);
  }
}
