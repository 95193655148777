@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Trigger {
  padding: px(1) px(2) px(1) px(1);
  border: 1px solid color(placeholder);
  border-radius: v(radius-input);
  outline-color: currentColor;

  &.isLoading {
    opacity: 0.5;
  }

  & > span {
    display: flex;
    width: 100%;
    align-items: center;
    gap: px(1);
    justify-items: flex-start;

    & > span {
      margin-left: auto;
    }
  }
}

.Content {
  z-index: 2;
  border: 1px solid color(placeholder);
  background-color: color(white);
  border-radius: v(radius-input);
}

.Item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: px(1);
  cursor: pointer;
  gap: px(1);
  justify-items: flex-start;

  &:hover {
    background-color: color(placeholder);
  }
}

.ColorSquare {
  width: px(2.5);
  height: px(2.5);
}
