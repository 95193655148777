@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.InputImageSmall {
  display: grid;
  width: 100%;
  gap: px(1);
  grid-template-columns: repeat(3, 1fr);

  .Button {
    padding: px(1);
  }

  .Image {
    border-radius: px(0.5);
  }

  &.isLoading {
    opacity: 0.5;
  }
}

.Chosen {
  margin-bottom: px(2);
}
