@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Page {
  position: relative;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  flex-direction: column;

  .Body {
    margin-bottom: px(4);
  }

  .Footer {
    margin-top: auto;
  }
}
