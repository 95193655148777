@import '../../../../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../../../../assets/styles/abstracts/functions';
@import '../../../../../../assets/styles/abstracts/mixins';

.ResetFiltersButton {
  display: inline-flex;
  align-items: center;
  margin-bottom: px(2);
  cursor: pointer;

  .Icon {
    margin-right: px(1);
    color: color(red);
  }

  .Label {
    font-weight: bold;
  }
}
