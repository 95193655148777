@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.InputSingleCheckbox {
  display: flex;
  flex-direction: column;

  .Checkbox {
    width: 18px;
    height: 18px;
    border: solid 1px color(gray, dark);
    margin-right: px(1);
    background-color: color(white);
    border-radius: v(radius-input);
    transition: color v(timings-xs), background-color v(timings-xs);

    &.isConfigurator {
      border-color: color(secondary);
      color: color(secondary);

      &:hover {
        background-color: color(secondary);
        color: color(white);
      }
    }

    &.isLoading {
      opacity: 0.5;
    }
  }

  .Label {
    cursor: pointer;
  }

  .Option {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: px(1);
    }
  }
}
