@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.InputSelect {
  position: relative;

  .Icon {
    position: absolute;
    top: 50%;
    right: px(2);
    transform: translateY(-50%);
  }

  .Select {
    width: 100%;
    padding: px(1) px(5) px(1) px(2);
    border: 1px solid color(placeholder);
    appearance: none;
    background-color: color(white);
    border-radius: v(radius-input);
    outline-color: currentColor;
  }

  &.isLoading {
    opacity: 0.5;
  }
}
