@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.ProjectHero {
  margin-block-end: px(3);
  margin-inline: calc(-50vw + 50%);
}

.CalendarSpacingGroup {
  align-items: center;
}

.Italic {
  font-style: italic;
  font-weight: 400;
}

.TitleSpacingGroup {
  align-items: flex-start;
}
