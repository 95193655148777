@import '../../../../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../../../../assets/styles/abstracts/functions';
@import '../../../../../../assets/styles/abstracts/mixins';

.Normal {
  @include font(label);
}
