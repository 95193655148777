@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Tooltip {
  padding: px(1) px(1.5);
  border: 1px solid color(gray);
  margin-top: px(1);
  background-color: color(white);
  box-shadow: v(shadow-medium);
  color: color(gray, dove);
}
