@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.ProgressLabel {
  display: inline-grid;
  color: v(progressColor);
  font-size: 11px;
  font-weight: bold;
  grid-template: 'percentage';

  & > * {
    grid-area: percentage;
    justify-self: end;
  }

  .Spacer {
    visibility: hidden;
  }
}

.Progress {
  grid-area: progress;
}
