@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.InputField {
  display: flex;
  flex-direction: column;
  margin-bottom: px(2);

  .Label {
    @include font(label);

    align-self: flex-start;
    margin-bottom: px(0.5);
    cursor: pointer;
  }

  .Tooltip {
    @include font(label);

    margin-left: auto;
  }

  .Description {
    margin-top: px(0.5);
  }

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: px(0.5);
  }

  .Error {
    margin-top: px(0.5);
  }
}
