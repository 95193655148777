@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Card {
  --layout: row;

  width: 100%;
  height: 100%;
  background-color: color(white);

  &.isRow {
    @include media-breakpoint-up(md) {
      --layout: column;
    }
  }

  .Layout {
    display: grid;
    width: 100%;
    grid-auto-flow: v(layout);
    grid-auto-rows: auto 1fr;
    text-align: start;
  }

  .Contents {
    height: 100%;
    flex-direction: column;
  }
}
