@mixin font($font: default) {
  font-family: fontvar(fontFamily, $font);
  font-size: fontvar(fontSize, $font);
  font-weight: fontvar(fontWeight, $font);
  line-height: fontvar(lineHeight, $font);
}

@mixin md($name) {
  $min: breakpoint-min($name, $grid-breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
