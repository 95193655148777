*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

html {
  font-family: v(font-fontFamily);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  tab-size: 4;
  text-size-adjust: 100%;
}

body {
  min-height: 100vh;
  margin: 0;
  color: color(gray, dark);
  line-height: inherit;

  @include font(default);
}

hr {
  height: 0;
  border-top-width: 1px;
  color: inherit;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}

cite {
  font-style: normal;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  border-color: inherit;
  border-collapse: collapse;
  text-indent: 0;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  margin: 0;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  appearance: none;
}

::-webkit-file-upload-button {
  appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  padding: 0;
  margin: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input,
textarea {
  *::placeholder {
    color: var(--color-placeholder, #9ca3af);
    opacity: 1;
  }
}

button,
[role='button'] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

/* stylelint-disable selector-class-pattern */
.Toastify__toast {
  @include font(default);
}
/* stylelint-enable selector-class-pattern */
