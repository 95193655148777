@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Content {
  @include font(dropdown);

  border: 1px solid color(gray);
  background-color: color(gray);
  box-shadow: v(shadow-medium);
  color: color(gray, dove);
  text-align: v(align);
}

.Item {
  padding: px(1) px(1.5);
  margin-bottom: 1px;
  background-color: color(white);
  cursor: pointer;

  &:focus-visible {
    color: color(primary);
    outline: 1px solid color(primary);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
