@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Pagination {
  justify-content: center;

  .Bullet {
    width: px(1.5);
    height: px(1.5);
    background-color: color(primary);
    border-radius: v(radius-round);
    cursor: pointer;
    opacity: 0.5;

    &.isActive {
      opacity: 1;
    }
  }
}
