@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Menu {
  display: flex;
  align-items: center;

  &.isMobile {
    flex-direction: column;
    align-items: initial;
  }

  .Link {
    @include font(h4);

    margin-left: px(8);

    &:first-child {
      margin-left: 0;
    }

    &.isMobile {
      margin-top: px(1);
      margin-left: 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
