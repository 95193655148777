@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.AdvisorCustomers {
  .Search {
    margin: px(2) 0;

    @include md(lg) {
      max-width: px(45);
    }
  }

  .TableWrapper {
    width: 100%;
    overflow-x: scroll;
  }
}
