@import '../../../../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../../../../assets/styles/abstracts/functions';
@import '../../../../../../assets/styles/abstracts/mixins';

.SolutionConfiguratorNumberOption {
  margin-bottom: px(3);

  .NumberInput {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: px(1) px(2);

    > * {
      flex: '1 1 auto';
    }
  }
}
